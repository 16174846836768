// $devices: ("", 0), (sm\:, 480px);
// @each $dev, $size in devices {
//   // this is gonna waste hella space
// }

/* layout */
.container {
  max-width: var(--max-w);
}

.hidden {
  display: none;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

/* flex & grid */
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

/* spacing */
$u: 0.25;
@each $i, $class in (p, padding), (m, margin) {
  @each $j, $size in (t, top), (r, right), (b, bottom), (l, left) {
    @each $k in 0,1,2,3,4,5,6,7,8 {
      .#{$i}#{$j}-#{$k} {
        #{$class}-#{$size}: #{$u * $k}rem;
      }
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/* typography */
$weights: (thin, 100), (extralight, 200), (light, 300), (normal, 400), 
          (medium, 500), (semibold, 600), (bold, 700), (extrabold, 800);
@each $k, $v in $weights {
  .font-#{$k} {
    font-weight: #{$v};
  }
}

$texts: (xs, 0.75), (sm, 0.875), (base, 1), (lg, 1.125), (xl, 1.25),
        (2xl, 1.5), (3xl, 1.875), (4xl, 2.25), (5xl, 3), (6xl, 3.75);
@each $k, $s in $texts {
  .text-#{$k} {
    font-size: #{$s}rem;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.list-disc {
  list-style-type: disc;
  padding-inline-start: 22px;
}

/* interactivity */
.cursor-pointer {
  cursor: pointer;
}